<template>
	<m-main-layout body-in-class="h100" out-class="h100" body-out-class="h100" :body-in-style="{padding:'0px !important'}">
		<div class="h100" style='background-image: url("/images/beij.png");background-repeat: no-repeat;background-size: 100% 100%;padding-top:200px;'>
			<div class="cl"></div>
			<div class="text-center f-50" style="color: #fff;font-weight: bold;line-height: 70px;">夷陵区在线督查平台</div>
			<div class="text-center f-50" style="color: #01376d;font-weight: bold;height: 70px;line-height: 70px;margin-top:-69px;margin-right: 2px;">夷陵区在线督查平台</div>
			<div style="width:1300px;margin:100px auto 0 auto;" v-if="total">
				<el-row :gutter="30">
					<el-col :span="6" v-for="(item,index) in total.type_list">
						<div style="background-repeat: no-repeat;background-size: 100% 100%;width: 100%;height: 160px;padding:40px 30px 50px 50px;" :style="{backgroundImage: 'url(\'/images/'+item.type+'.png\')'}">
							<div class="cl light f-28">
								<div class="z">{{item.type_str}}</div>
								<div class="y"><b class="mg-l-5">{{item.all_count}}</b></div>
							</div>
							<div class="cl light f-20 mg-t-10">
								<div class="z">已办结<b class="mg-l-5">{{item.state_4}}</b></div>
								<div class="y">办理中<b class="mg-l-5">{{item.state_3}}</b></div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</m-main-layout>
</template>

<script>
export default {
	name: "DashBoard",
	data() {
		return {
			username: this.$user.getUserName(),
			today: '',
			
			user_total_time: '',
			start_time: '',
			end_time: '',
			
			favoriteList:[],
			favoriteSearch:{
				akey:'program',
				type:'favorite',
				uid:this.$user.getUid(),
				page:1,
				pageSize:10
			},
			favoriteTotal:0,
			
			BsyList:[],
			DeptList:[],
			dubanList:[],
			
			total:null
		}
	},
	created() {
	
	},
	mounted() {
		this.getTotal();
		// this.getMyFavorite();
		// this.getMyDeptList();
		//
		// if(this.$user.getJob() === 'bsy'){
		// 	this.getMyBsyList();
		// }else if(this.$user.getJob() === 'dby'){
		// 	this.getMyDubanList();
		// }
	},
	methods: {
		getTotal() {
			let _this = this;
			_this.$http.request({
				url: "/SystemTotal/getTotal",
				datas: null,
				success(res) {
					_this.total = res;
				}
			})
		},
		getMyDubanList(){
			let _this = this;
			this.$http.request({
				url:'/Program/listPage',
				datas:{dby_ids:this.$user.getUid()},
				success(res){
					_this.dubanList = res.list;
				}
			})
		},
		getMyDeptList(){
			let _this = this;
			this.$http.request({
				url:'/Program/listPage',
				datas:{dept_id:this.$user.getDepartmentId()},
				success(res){
					_this.DeptList = res.list;
				}
			})
		},
		getMyBsyList(){
			let _this = this;
			this.$http.request({
				url:'/Program/listPage',
				datas:{bsy_ids:[this.$user.getUid(),'find_in_set']},
				success(res){
					_this.BsyList = res.list;
				}
			})
		},
		getMyFavorite(){
			let _this = this;
			this.$http.request({
				url:'/UserLog/listPage',
				datas:this.favoriteSearch,
				success(res){
					console.log(res);
					_this.favoriteList = res.list;
					_this.favoriteTotal = res.totalCount;
				}
			})
		},
		pageFavoriteChange(e){
			console.log(e);
			this.favoriteSearch.page = e;
			this.getMyFavorite();
		},
		changeFavoriteSize(e){
			console.log(e);
			this.favoriteSearch.pageSize = e;
			this.getMyFavorite();
		}
	}
}
</script>
<style>
.adminLogin {
	width: 100%;
	text-align: center;
	font-size: 24px;
	margin-top: 200px;
	font-weight: 300;
}
</style>
